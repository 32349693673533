import qs from 'qs'
import SessionStorageKeys from "@/common/SessionStorageKeys";
import {useRequest} from "vue-request";
import {message} from "ant-design-vue";

export class Request {
    
    public baseUrl: string = process.env.VUE_APP_BASE_URL;
    
    public path!: string;
    
    public savePath: string = 'save';
    
    public listPath: string = 'list';
    
    public onOffPath: string = 'onOff';
    
    headers(requestBody: boolean = true): any {
        return new Headers({
            'token': sessionStorage.getItem(SessionStorageKeys.token) || '',
            'Content-Type': requestBody? 'application/json': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
    }
    
    get = (url: string) => {
        let queryData = () => this.initGetData(url);
        let run = this.initRun(queryData);
        return run();
    };
    
    post = (url: string, data: any) => {
        let queryData = (params: any) => this.initPostData(url, params);
        let run = this.initRun(queryData);
        return run(data);
    };
    
    formPost = (url: string, data: any = {}, manual: boolean = false) => {
        let queryData = (params: any) => this.initFormData(url, params);
        let run = this.initRun(queryData);
        return run(data);
    };
    
    delete = (url: string) => {
        let deleteData = () => this.initDeleteData(url);
        let run = this.initRun(deleteData);
        return run();
    };
    
    list = (data: any) => {
        return this.formPost(`${this.path}/${this.listPath}`, data);
    };
    
    save = (data: any) => {
        return this.post(`${this.path}/${this.savePath}`, data);
    };
    
    getById = (id: string) => {
        return this.get(`${this.path}/${id}`);
    };
    
    deleteById = (id: string) => {
        return this.delete(`${this.path}/${id}`);
    };
    
    onOff = (data: {id: string, status: number}) => {
        return this.formPost(`${this.path}/${this.onOffPath}`, data);
    };
    
    service = (params: any) => {
        return Object.assign({
            headers: this.headers(params.requestBody)
        }, params);
    };
    
    initGetData = (url: string) => {
        return {
            url: `${this.baseUrl}${url}`,
            method: 'GET',
            headers: this.headers()
        }
    };
    
    initPostData = (url: string, data: any) => {
        return {
            url: `${this.baseUrl}${url}`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: this.headers()
        }
    };
    
    initFormData = (url: string, data: any) => {
        return {
            url: `${this.baseUrl}${url}`,
            method: 'POST',
            body: qs.stringify(data),
            headers: this.headers(false)
        };
    };
    
    initDeleteData = (url: string) => {
        return {
            url: `${this.baseUrl}${url}`,
            method: 'DELETE',
            headers: this.headers()
        }
    };
    
    initRun = (queryData: any) => {
        let { run } = useRequest(queryData, {
            onSuccess(data: any) {
                if (data.code !== 0) {
                    message.error(data.msg, 3);
                }
            }
        });
        return run;
    };
    
}

export default new Request();